import React from "react"
import { graphql, Link } from "gatsby"

import Syllabics from "../components/Syllabics"

// Components
import SEO from "../components/seo"
import Layout from "../components/layout"
import BackgroundImage2 from "../components/BackgroundImage"
import CommunityLogo from "../components/communityLogo"
import BackgroundImage from "gatsby-background-image"
import GatsbyImage from "gatsby-image"
import Hero from "../components/Hero"

const Communities = ({ data }) => {
  const communitiesPageData = data.allSanityCommunitiesLandingPage.edges[0].node
  const { englishTitle, syllabicsTitle, heroImage } = communitiesPageData
  const communities = data.allSanityIndividualCommunityPages.edges
  const { kingfisher, kasabonika, wapekeka, wunnumin, wawakapewin } = data

  const hero = heroImage ? heroImage.asset : data.heroDefault.childImageSharp
  const Logo = ({
    className = "",
    logoClassName = "",
    fluid = null,
    to = "/",
  }) => (
    <div className={`   ${className}`}>
      <Link to={to}>
        <GatsbyImage
          fluid={fluid}
          className={`w-20 h-20 sm:w-24 sm:h-24 md:w-32 md:h-32 lg:w-40 lg:h-40 xl:w-52 xl:h-52 transform hover:scale-110 duration-150 ease-in-out ${logoClassName} `}
        />
      </Link>
    </div>
  )
  return (
    <Layout>
      <SEO title="Communities" />
      <Hero
        englishTitle={englishTitle}
        syllabics={syllabicsTitle}
        heroImage={hero}
      />
      <div className="  grid ">
        <div className=" w-7/12 self-center  mx-auto mt-10 md:mt-14 md:mb-10 relative rounded-full md:p-10">
          <GatsbyImage
            fluid={data.backgroundImage.childImageSharp.fluid}
            className=" relative  opacity-25"
          />
          <div className="absolute  top-0 right-0 bottom-0 left-0 grid grid-rows-3">
            <div className="flex justify-center">
              <Logo
                className="-mt-5 lg:-mt-10"
                fluid={kasabonika.childImageSharp.fluid}
                to={"/communities/kasabonika-lake-first-nation"}
              />
            </div>
            <div className="flex justify-between items-center">
              <Logo
                className="-ml-5 md:-ml-10"
                fluid={wunnumin.childImageSharp.fluid}
                to="/communities/wunnumin-lake-first-nation"
              />
              <Logo
                className="-mr-5 md:-mr-10"
                fluid={kingfisher.childImageSharp.fluid}
                to="/communities/kingfisher-lake-first-nation"
              />
            </div>
            <div className="flex justify-around items-end">
              <Logo
                className="-mb-2 sm:-mb-5"
                fluid={wawakapewin.childImageSharp.fluid}
                to="/communities/wawakapewin-first-nation"
              />
              <Logo
                className="-mb-1 sm:-mb-2"
                fluid={wapekeka.childImageSharp.fluid}
                to="/communities/wapekeka-first-nation"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Below Mimics the <Hero> component, but has a few different needs */}
    </Layout>
  )
}

export default Communities

export const query = graphql`
  query communitiesLandingPage {
    allSanityCommunitiesLandingPage {
      edges {
        node {
          id
          englishTitle
          syllabicsTitle
          heroImage {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
    allSanityIndividualCommunityPages(
      sort: { fields: communityName, order: ASC }
    ) {
      edges {
        node {
          communityName
          communityLogo {
            asset {
              assetId
              fluid {
                ...GatsbySanityImageFluid
              }
            }
            altText
          }
          id
          slug {
            current
          }
        }
      }
    }
    heroDefault: file(relativePath: { eq: "headers/pelicanfalls.jpg" }) {
      id
      childImageSharp {
        fluid(maxWidth: 1000, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    logoDefault: file(relativePath: { eq: "Shibogama_Logo.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    backgroundImage: file(relativePath: { eq: "graphics/Goose-Circle-1.png" }) {
      name
      childImageSharp {
        fluid(maxWidth: 1500, quality: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    kingfisher: file(relativePath: { eq: "logos/Kingfisher Lake.png" }) {
      name
      childImageSharp {
        id
        fluid(maxWidth: 250, quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    kasabonika: file(relativePath: { eq: "logos/Kasabonika Lake.png" }) {
      name
      childImageSharp {
        id
        fluid(maxWidth: 250, quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wapekeka: file(relativePath: { eq: "logos/Wapekeka.png" }) {
      name
      childImageSharp {
        id
        fluid(maxWidth: 250, quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wunnumin: file(relativePath: { eq: "logos/Wunnumin Lake.png" }) {
      name
      childImageSharp {
        id
        fluid(maxWidth: 250, quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wawakapewin: file(relativePath: { eq: "logos/Wawakapewin.png" }) {
      name
      childImageSharp {
        id
        fluid(maxWidth: 250, quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
